const authForm = {
  /**
   * Auth form key
   * @type {String}
   */
  key: "authForm",

  /**
   * Auth update form fields object
   * @type {Object}
   */
  fields: {
    avatar: null,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    calendarId: null,
    cancellationMargin: 0,
    // notifyMe: "all_staff",
    // appointmentNotifications: true,
    // cancelationNotifications: true,
    // placesNotifications: [1, 2]
  },

  /**
   * Auth form errors object
   * @type {Object}
   */
  errors: {},

  /**
   * Auth form mode
   * @type {String}
   */
  mode: "create",

  /**
   * Auth form path
   * @type {String}
   */
  path: "/api/b2b/auth/update",

  /**
   * Auth form helpers
   * @type {Object}
   */
  helpers: {
    notifyMe: "all_staff",
    appointmentNotifications: true,
    cancelationNotifications: true,
    placesNotifications: [1, 2],
  }
};

export default authForm;
