import authForm from "@/core/services/forms/authForm.js";
import { INIT_FORM } from "@/core/services/store/form.module.js";
//import store from "@/core/services/store";

export default {
  methods: {
    /**
     * Init company form with edit mode
     * @param {Object} user
     * @return {void}
     */
    editAuth(user) {
      let form = JSON.parse(JSON.stringify(authForm));
      form.mode = "edit";
      form.fields = {
        avatar: null,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        email: user.email,
        currentPassword: null,
        newPassword: null,
        calendarId: null,
        cancellationMargin: null,
      };
      form.fields._method = "PUT";

      this.$store.dispatch(INIT_FORM, form);
      // let staffMember = null;
      // let form = JSON.parse(JSON.stringify(authForm));
      // if( this.$store.getters.currentPlace &&  this.$store.getters.currentPlace.staff != null) {
      //   staffMember =  this.$store.getters.currentPlace?.staff.find((staff) => staff.id === user.id);

      //   this.$store.dispatch(SET_FORM_FIELD, {
      //     formKey: form.key,
      //     fieldName: 'calendarId',
      //     value: staffMember?.calendar.id
      //   })

      //   this.$store.dispatch(SET_FORM_FIELD, {
      //     formKey: form.key,
      //     fieldName: 'cancellationMargin',
      //     value: staffMember?.calendar.cancellationMargin
      //   })

      //   form.helpers = this.updateFormHelpers(form.helpers, staffMember);

      // } else {
      //   form.mode = "edit";
      //   form.fields = {
      //     avatar: null,
      //     firstName: user.firstName,
      //     lastName: user.lastName,
      //     phone: user.phone,
      //     email: user.email,
      //     currentPassword: null,
      //     newPassword: null,
      //     calendarId: null,
      //     cancellationMargin: null
      //   };
      //   form.fields._method = "PUT";

      //   this.$store.dispatch(INIT_FORM, form);
      // }
    },

    /**
     * Update form helpers
     * @param {Object} formHelpers
     * @param {Object} staffMember
     * @return {Object}
     */
    updateFormHelpers(formHelpers, staffMember) {
      formHelpers.cancellationMarginObject = this.generateMarginObject(
        staffMember?.calendar.cancellationMargin
      );
      return formHelpers;
    },

    /**
     * Update form fields after place change
     * @param {Object} staffMember
     * @param {Object} formFields
     */
    updateCancellationFormFields(formFields, staffMember) {
      this.form.fields.calendarId = staffMember?.calendar.id;
      this.form.fields.cancellationMargin =
        staffMember?.calendar.cancellationMargin;
      this.cancellationMarginObject = this.generateMarginObject(
        staffMember.calendar.cancellationMargin
      );

      return formFields;
    },

    /**
     * Generate margin object for form helpers
     * @param {Number} minutes
     * @return {Object}
     */
    generateMarginObject(minutes) {
      let marginObject = {
        days: Math.floor(minutes / 60 / 24).toString(),
        hours: Math.floor((minutes / 60) % 24).toString(),
        minutes: (minutes % 60).toString(),
      };

      return marginObject;
    },
  },
};
