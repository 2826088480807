<template>
  <div id="profile_page_wrapper">
    <div class="bb-mobile-header">
      <div class="bb-mobile-header-left">
        <button class="mobile-sidebar-btn" @click="openMobileAside()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
      <h1>{{ $t("profile.title") }}</h1>
      <div class="bb-mobile-header-right"></div>
    </div>
    <div class="d-flex justify-content-between align-items-top">
      <div class="bb-profile-form-guide">
        <div class="personal-details-guide">
          <h4>{{ $t("profile.guide.personalDetails.title") }}</h4>
          <p class="guide-description">
            {{ $t("profile.guide.personalDetails.description") }}
          </p>
        </div>
        <div class="change-password-guide">
          <h4>{{ $t("profile.guide.changePassword.title") }}</h4>
          <p class="guide-description">
            {{ $t("profile.guide.changePassword.description") }}
          </p>
        </div>
        <div
          v-if="!$hasRole(acl.roles.owner)"
          class="change-cancellation-guide"
        >
          <h4>{{ $t("profile.guide.changeCancellation.title") }}</h4>
          <p class="guide-description">
            {{ $t("profile.guide.changeCancellation.description") }}
          </p>
        </div>
        <!-- <div class="notification-settings-guide">
          <h4>{{ $t("profile.guide.notificationSettings.title") }}</h4>
          <p class="guide-description">
            {{ $t("profile.guide.notificationSettings.description") }}
          </p>
        </div> -->
      </div>
      <div class="bb-profile-form-container">
        <div class="bb-card profile-page-card">
          <div class="bb-profile-picture-container">
            <div
              v-show="!profilePictureUrl"
              class="bb-profile-picture-placeholder"
            >
              <svg
                width="36"
                height="39"
                viewBox="0 0 36 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 6H5C3.93913 6 2.92172 6.42143 2.17157 7.17157C1.42143 7.92172 1 8.93913 1 10V30M1 30V34C1 35.0609 1.42143 36.0783 2.17157 36.8284C2.92172 37.5786 3.93913 38 5 38H29C30.0609 38 31.0783 37.5786 31.8284 36.8284C32.5786 36.0783 33 35.0609 33 34V26M1 30L10.172 20.828C10.9221 20.0781 11.9393 19.6569 13 19.6569C14.0607 19.6569 15.0779 20.0781 15.828 20.828L21 26M33 15.5V26M33 26L29.828 22.828C29.0779 22.0781 28.0607 21.6569 27 21.6569C25.9393 21.6569 24.9221 22.0781 24.172 22.828L21 26M21 26L25 30M27 5H35M31 1V9M21 14H21.02"
                  stroke="#99ACC2"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <img
              v-show="profilePictureUrl"
              :src="profilePictureUrl"
              class="bb-profile-picture-preview"
            />
            <input
              type="file"
              style="display: none"
              id="profile_picture_input"
              @change="onFileChange"
            />
            <button class="bb-profile-picture-btn" @click="uploadImage">
              {{ $t("profile.personalDetailsCard.uploadAvatarBtn") }}
            </button>
            <div class="bb-invalid-feedback" v-show="form.errors.avatar">
              {{ form.errors.avatar }}
            </div>
          </div>
          <div class="mobile-guide">
            <h4>{{ $t("profile.guide.personalDetails.title") }}</h4>
            <p class="mobile-guide-description">
              {{ $t("profile.guide.personalDetails.description") }}
            </p>
          </div>
          <div class="bb-form-group inline-form-group">
            <div class="bb-form-group mr-2">
              <label>{{
                $t("profile.personalDetailsCard.firstNameInput.label")
              }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.firstName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.firstName">
                {{ form.errors.firstName }}
              </div>
            </div>
            <div class="bb-form-group ml-2">
              <label>{{
                $t("profile.personalDetailsCard.lastNameInput.label")
              }}</label>
              <input
                type="text"
                class="bb-input"
                v-model="form.fields.lastName"
              />
              <div class="bb-invalid-feedback" v-show="form.errors.lastName">
                {{ form.errors.lastName }}
              </div>
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("profile.personalDetailsCard.phoneInput.label")
            }}</label>
            <input type="text" class="bb-input" v-model="form.fields.phone" />
            <div class="bb-invalid-feedback" v-show="form.errors.phone">
              {{ form.errors.phone }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("profile.personalDetailsCard.emailInput.label")
            }}</label>
            <input type="email" class="bb-input" v-model="form.fields.email" />
            <div class="bb-invalid-feedback" v-show="form.errors.email">
              {{ form.errors.email }}
            </div>
          </div>
          <hr />
          <div class="mobile-guide">
            <h4>{{ $t("profile.guide.changePassword.title") }}</h4>
            <p class="mobile-guide-description">
              {{ $t("profile.guide.changePassword.description") }}
            </p>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("profile.personalDetailsCard.currentPasswordInput.label")
            }}</label>
            <div class="bb-input-group password-input">
              <input
                :type="currentPasswordInputType"
                v-model="form.fields.currentPassword"
              />
              <i
                :class="currentPasswordIconClass"
                @click="toggleCurrentPasswordType()"
              ></i>
            </div>
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.currentPassword"
            >
              {{ form.errors.currentPassword }}
            </div>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("profile.personalDetailsCard.newPasswordInput.label")
            }}</label>
            <div class="bb-input-group new-password-input">
              <input
                :type="newPasswordInputType"
                v-model="form.fields.newPassword"
              />
              <i
                :class="newPasswordIconClass"
                @click="toggleNewPasswordType()"
              ></i>
            </div>
            <div class="bb-invalid-feedback" v-show="form.errors.newPassword">
              {{ form.errors.newPassword }}
            </div>
          </div>
          <hr />
          <div v-if="!$hasRole(acl.roles.owner)" class="bb-form-group">
            <label>{{
              $t("calendarForm.advancedSettings.cancellationMarginInput.label")
            }}</label>
            <p class="bb-input-description">
              {{
                $t(
                  "calendarForm.advancedSettings.cancellationMarginInput.locationDescription"
                )
              }}
            </p>
            <div class="margin-wrapper">
              <BBSelect
                class="places-select"
                :options="placesAsOptions"
                v-model="selectedPlace"
              ></BBSelect>
            </div>
            <p v-show="selectedPlace" class="bb-input-description">
              {{
                $t(
                  "calendarForm.advancedSettings.cancellationMarginInput.description"
                )
              }}
            </p>
            <div v-show="selectedPlace" class="margin-wrapper">
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="daysOptions"
                  v-model="cancellationMarginObject.days"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.daysLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="hoursOptions"
                  v-model="cancellationMarginObject.hours"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.hoursLabel"
                  )
                }}</span>
              </div>
              <div class="d-flex justify-content-start align-items-center">
                <BBSelect
                  :options="minutesOptions"
                  v-model="cancellationMarginObject.minutes"
                ></BBSelect>
                <span class="cancellation-margin-text">{{
                  $t(
                    "calendarForm.advancedSettings.cancellationMarginInput.minutesLabel"
                  )
                }}</span>
              </div>
            </div>
            <div
              class="bb-invalid-feedback"
              v-show="form.errors.cancellationMargin"
            >
              {{ form.errors.cancellationMargin }}
            </div>
          </div>
        </div>
        <hr class="mobile-divider" />
        <!--
        <div
          class="bb-card profile-page-card mt-5 mb-10 notifications-settings-card"
        >
          <div class="mobile-guide">
            <h4>{{ $t("profile.guide.notificationSettings.title") }}</h4>
            <p class="mobile-guide-description">
              {{ $t("profile.guide.notificationSettings.description") }}
            </p>
          </div>
          <div class="bb-form-group">
            <label>{{
              $t("profile.notificationsCard.notifyMeInput.label")
            }}</label>
            <BBSelect
              :options="notifyOptions"
              v-model="form.helpers.notifyMe"
              class="notify-me-select"
            >
              <template v-slot:optionItem="{ option }">
                <i
                  :class="
                    option.value === form.helpers.notifyMe ? 'fas fa-check' : ''
                  "
                ></i>
                <span>{{ option.text }}</span>
              </template>
            </BBSelect>
          </div>
          <div class="bb-form-group">
            <div
              class="notify-locations"
              v-show="form.helpers.notifyMe === 'all_staff'"
            >
              <label>{{
                $t("profile.notificationsCard.bookedInput.label")
              }}</label>
              <div class="notify-locations-container">
                <span>{{
                  $t("profile.notificationsCard.bookedInput.allLocationsLabel")
                }}</span>
                <button @click="showPlacesModal">
                  {{ $t("profile.notificationsCard.bookedInput.changeBtn") }}
                </button>
              </div>
            </div>
            <div
              class="mobile-notify-locations"
              v-show="form.helpers.notifyMe === 'all_staff'"
              @click="showPlacesModal"
            >
              <span>{{
                $t("profile.notificationsCard.bookedInput.allLocationsLabel")
              }}</span>
              <i class="la la-angle-right"></i>
            </div>
            <BBCheckbox
              :label="
                $t('profile.notificationsCard.bookedInput.newAppointmentsLabel')
              "
              v-model="form.helpers.appointmentNotifications"
              class="mt-5"
            />
            <BBCheckbox
              :label="
                $t('profile.notificationsCard.bookedInput.cancellationLabel')
              "
              v-model="form.helpers.cancelationNotifications"
              class="mt-3"
            />
          </div>
        </div>-->
      </div>
      <!-- start::crop_image_modal -->
      <b-modal ref="cropImageModal" id="crop_image_modal">
        <template v-slot:modal-header="{ close }">
          <div class="bb-modal-header">
            <h4>{{ $t("profile.croperModal.title") }}</h4>
            <button style="padding-left: 10px" @click="close">
              <i class="ki ki-close icon-md"></i>
            </button>
          </div>
        </template>
        <vue-cropper
          ref="cropper"
          :src="uploadImageUrl"
          class="profile-picture-cropper"
        />
        <template v-slot:modal-footer>
          <div class="bb-modal-footer">
            <button class="bb-btn btn-success btn-lg" @click="cropImage()">
              {{ $t("profile.croperModal.saveBtn") }}
            </button>
          </div>
        </template>
      </b-modal>
      <!-- end::crop_image_modal -->
      <!-- start::select_locations_modal 
      <b-modal ref="selectPlacesModal" id="select_locations_modal">
        <template v-slot:modal-header="{ close }">
          <div class="bb-modal-header">
            <h4>{{ $t("profile.locationsModal.title") }}</h4>
            <button style="padding-left: 10px" @click="close">
              <i class="ki ki-close icon-md"></i>
            </button>
          </div>
        </template>
        <BBCheckboxGroup
          :options="placesAsOptions"
          :allCheckedLabel="$t('profile.locationsModal.allPlacesLabel')"
          elementId="places_checkbox_group"
          v-model="form.helpers.placesNotifications"
        />
        <template v-slot:modal-footer>
          <div class="bb-modal-footer">
            <button class="bb-btn btn-success btn-lg">
              {{ $t("profile.locationsModal.saveBtn") }}
            </button>
          </div>
        </template>
      </b-modal>-->
      <!-- end::select_locations_modal -->
    </div>
    <div class="d-flex justify-content-end submit-btn-container">
      <button class="bb-btn btn-success btn-lg" @click="submit()">
        {{ $t("profile.saveBtn") }}
      </button>
    </div>
    <div class="mobile-submit-btn-container">
      <button class="bb-btn btn-success btn-lg" @click="submit()">
        {{ $t("profile.saveBtn") }}
      </button>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PLACES } from "@/core/services/store/places.module";
import { SET_PLACE } from "@/core/services/store/places.module.js";
import { mapGetters } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import BBSelect from "@/view/content/BBForm/BBSelect.vue";
//import BBCheckbox from "@/view/content/BBForm/BBCheckbox.vue";
//import BBCheckboxGroup from "@/view/content/BBForm/BBCheckboxGroup.vue";
import formMixin from "@/core/mixins/formMixin.js";
import authForm from "@/core/services/forms/authForm.js";
import authFormMixin from "@/core/mixins/authFormMixin.js";
import { SET_ALERT_MESSAGES } from "@/core/services/store/alert.module.js";
import { VERIFY_AUTH } from "@/core/services/store/auth.module.js";
import mobileAsideMixin from "@/core/mixins/mobileAsideMixin.js";

export default {
  name: "Profile",
  components: { VueCropper, BBSelect },
  mixins: [formMixin, authFormMixin, mobileAsideMixin],
  data() {
    return {
      uploadedImage: null,
      uploadImageUrl: null,
      // notifyOptions: [
      //   {
      //     text: this.$t(
      //       "profile.notificationsCard.notifyMeInput.options.allStaff"
      //     ),
      //     value: "all_staff"
      //   },
      //   {
      //     text: this.$t(
      //       "profile.notificationsCard.notifyMeInput.options.meOnly"
      //     ),
      //     value: "me_only"
      //   }
      // ],
      currentPasswordInputType: "text",
      newPasswordInputType: "password",
      selectedPlace: null,
    };
  },
  beforeMount() {
    this.form = JSON.parse(JSON.stringify(authForm));
    this.cancellationMarginObject = {};
  },
  mounted() {
    // Dispatch store SET_BREADCRUMB action to set page title
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("profile.title") }]);
    // Dispatch store GET_PLACES action to get places from api
    this.$store.dispatch(GET_PLACES);
    // Update form from current user data
    this.editAuth(this.currentUser);
  },
  methods: {
    /**
     * Imitate click on hidden file input for image upload
     * @return {void}
     */
    uploadImage() {
      document.querySelector("#profile_picture_input").click();
    },

    /**
     * Handle profile picture upload
     * @param {Object} event
     * @return {void}
     */
    onFileChange(event) {
      this.showCropperModal();
      this.uploadedImage = event.target.files[0];
      this.uploadImageUrl = URL.createObjectURL(this.uploadedImage);
    },

    /**
     * Show crop image modal
     * @return {void}
     */
    showCropperModal() {
      this.$refs.cropImageModal.show();
    },

    /**
     * Hide crop image modal
     * @return {void}
     */
    hideCropperModal() {
      this.$refs.cropImageModal.hide();
    },

    /**
     * Show select locations(places) image modal
     * @return {void}
     *
    showPlacesModal() {
      this.$refs.selectPlacesModal.show();
    },

    /**
     * Hide select locations(places) image modal
     * @return {void}
     *
    hidePlacesModal() {
      this.$refs.selectPlacesModal.hide();
    },

    /**
     * Get cropped image from cropper and set image to form
     * @return {void}
     */
    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.form.fields.avatar = blob;
      });
      this.hideCropperModal();
    },

    /**
     * Toggle current password input type
     * @return {void}
     */
    toggleCurrentPasswordType() {
      if (this.currentPasswordInputType === "password") {
        this.currentPasswordInputType = "text";
      } else {
        this.currentPasswordInputType = "password";
      }
    },

    /**
     * Toggle new password input type
     * @return {void}
     */
    toggleNewPasswordType() {
      if (this.newPasswordInputType === "password") {
        this.newPasswordInputType = "text";
      } else {
        this.newPasswordInputType = "password";
      }
    },

    /**
     * Return number of minutes from marginObject that contains days, hours and minutes properties
     * @param {Object} marginObject
     * @return {Number}
     */
    getMinutes(marginObject) {
      let daysInMinutes = parseInt(marginObject.days) * 60 * 24;
      let hoursInMinutes = parseInt(marginObject.hours) * 60;
      let result =
        daysInMinutes + hoursInMinutes + parseInt(marginObject.minutes);
      return result;
    },

    rearrangeForm() {
      this.form.fields.cancellationMargin = this.getMinutes(
        this.cancellationMarginObject
      );
    },

    /**
     * Submit the form
     * @return {void}
     */
    submit() {
      this.rearrangeForm();
      this.submitForm()
        .then((response) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: [response.data.message],
            errorMessages: null,
          });
          this.$store.dispatch(VERIFY_AUTH);
        })
        .catch((error) => {
          this.$store.dispatch(SET_ALERT_MESSAGES, {
            successMessages: null,
            errorMessages: [error.response.data.message],
          });
        });
    },
  },
  computed: {
    // ...mapGetters(["currentUser", "placesAsOptions"]),
    ...mapGetters([
      "currentUser",
      "currentPlace",
      "placesAsOptions",
      "allPlaces",
    ]),

    /**
     * Return profile picture url
     * @return {String|null}
     */
    profilePictureUrl() {
      if (this.form.fields.avatar) {
        return URL.createObjectURL(this.form.fields.avatar);
      }

      if (this.currentUser.avatar && this.currentUser.avatar.url) {
        return this.currentUser.avatar.url;
      }

      return null;
    },

    /**
     * Return class name for current password input icon class
     * @return {String}
     */
    currentPasswordIconClass() {
      if (this.currentPasswordInputType === "password") {
        return "far fa-eye-slash";
      }

      return "far fa-eye";
    },

    /**
     * Return class name for new password input icon class
     * @return {String}
     */
    newPasswordIconClass() {
      if (this.newPasswordInputType === "password") {
        return "far fa-eye-slash";
      }

      return "far fa-eye";
    },

    /**
     * Return array of days options
     * @return {Array}
     */
    daysOptions() {
      let options = [];
      for (let i = 0; i < 15; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },

    /**
     * Return array of hours options
     * @return {Array}
     */
    hoursOptions() {
      let options = [];
      for (let i = 0; i < 24; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },

    /**
     * Return array of minutes options
     * @return {Array}
     */
    minutesOptions() {
      let options = [];
      for (let i = 0; i < 60; i++) {
        let option = {
          text: i.toString(),
          value: i.toString(),
        };
        options.push(option);
      }
      return options;
    },
  },
  watch: {
    /**
     * Watch current user change
     */
    currentUser: {
      immediate: true,
      handler() {
        this.editAuth(this.currentUser);
      },
    },

    /**
     * Watch current place change
     */
    currentPlace: {
      handler() {
        if (this.currentPlace) {
          if (this.currentPlace.id != this.selectedPlace) {
            this.selectedPlace = this.currentPlace.id;
          }
        }
      },
    },

    /**
     * Watch selected place change
     */
    selectedPlace: {
      immediate: true,
      handler() {
        let place = this.$store.getters.allPlaces.find(
          (place) => place.id === this.selectedPlace
        );
        this.$store.dispatch(SET_PLACE, place);
        let staff = place?.staff.find(
          (staff) => staff.id === this.currentUser.id
        );
        if (staff && staff.calendar) {
          this.updateCancellationFormFields(this.form.fields, staff);
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/bb-styles/pages/profile.scss";
</style>
